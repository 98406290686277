import axios from "axios";

import { env } from "@/env.cjs";
import request from "./axios.request";
import response from "./axios.response";

const axiosInstance = axios.create({
	baseURL: env.NEXT_PUBLIC_API_BASE_URL,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
});

axiosInstance.interceptors.request.use(request.onRequest, request.onRequestError);
axiosInstance.interceptors.response.use(response.onResponse, response.onResponseError);

export default axiosInstance;
