import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { masterAccessTokenAtom } from "@/atoms/authAtoms";
import axios from "@/services/axios.config";
import { authStore } from "@/stores/auth.store";
import { toastOnError } from "@/utils/toastOnError";
import type { MasterAccessTokenReqParams } from "schemas/auth";

type ResponseData = {
	masterAccessToken: string;
};

const getMasterAccessToken = (data: MasterAccessTokenReqParams) =>
	axios
		.post<APIResponse<ResponseData>>("/auth/master/access", data)
		.then((res) => res.data.data)
		.catch((err) => Promise.reject(err?.response?.data as APIResponse));

export default function useMasterAccessToken() {
	const router = useRouter();
	const redirectURL = router.query?.redirectTo as string | undefined;

	return useMutation(getMasterAccessToken, {
		retry: 0,
		mutationKey: ["masterAccessToken"],
		onSuccess: (data) => {
			authStore.set(masterAccessTokenAtom, data?.masterAccessToken);
			if (redirectURL) {
				redirectURL.startsWith("/auth") ? router.push(redirectURL) : router.push(`/auth?redirectTo=${redirectURL}`);
			} else {
				router.push("/auth");
			}
		},
		onError: (err: APIResponse) => {
			toastOnError(err, err?.msg);
		},
	});
}
