import axios from "axios";
import helpers, { handleLogOut } from "./axios.helpers";

const onResponse = (response: any) => {
	return response;
};

const onResponseError = async (err: any) => {
	const originalConfig = err.config;
	if (
		err?.response &&
		err.response?.status === 401 &&
		!originalConfig._retry &&
		!originalConfig?.url?.includes("auth/token/refresh")
	) {
		if (originalConfig?.url?.includes("auth/master/validate")) {
			handleLogOut("Master access token is expired");
			return Promise.reject(err);
		}

		originalConfig._retry = true;
		// Refresh tokens
		const res = await helpers.memoizedRefreshToken();

		// Retry original request
		if (res?.execStatus) {
			helpers.setTokenInHeader(originalConfig);
			if (typeof originalConfig.data === "string") originalConfig.data = JSON.parse(originalConfig.data);

			return axios.request(originalConfig);
		}
	}

	return Promise.reject(err);
};

const response = { onResponse, onResponseError };

export default response;
