import z from "zod";

export const triggerResetPasswordReqParams = z.object({
	email: z.string().email(),
});

export const resetPasswordReqParams = z.object({
	newPassword: z.string().trim().min(1, "Password is required"),
	resetPasswordToken: z.string().trim().min(1, "Password is required"),
});

export const resetPasswordFormData = z
	.object({
		password: z.string().trim().min(1, "Password is required"),
		confirmPassword: z.string().trim().min(1, "Confirm Password is required"),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: "Passwords do not match",
		path: ["confirmPassword"],
	});

export type TriggerResetPasswordReqParams = z.infer<typeof triggerResetPasswordReqParams>;
export type ResetPasswordReqParams = z.infer<typeof resetPasswordReqParams>;
export type ResetPasswordFormData = z.infer<typeof resetPasswordFormData>;
