import { zodResolver } from "@hookform/resolvers/zod";
import dynamic from "next/dynamic";
import { useForm } from "react-hook-form";
import { FiMessageCircle } from "react-icons/fi";

import InputField from "@/components/shared/formFields/input";
import SEO from "@/components/shared/SEO";
import Button from "@/components/shared/ui/Button";
import useMasterAccessToken from "@/hooks/auth/useGetMasterAccessToken";
import { masterAccessTokenReqParams, MasterAccessTokenReqParams } from "@/schemas/auth";

const AnimatedLogo = dynamic(() => import("@/components/shared/animatedLogo"));

const Home = () => {
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm<MasterAccessTokenReqParams>({
		resolver: zodResolver(masterAccessTokenReqParams),
		mode: "onBlur",
		reValidateMode: "onBlur",
	});

	const { mutateAsync: masterLogin, isLoading } = useMasterAccessToken();

	const onSubmit = async ({ password }: MasterAccessTokenReqParams) => {
		masterLogin({ password }).then(() => reset());
	};

	return (
		<>
			<SEO title="Home" />

			<div className="flex min-h-screen items-center justify-center bg-gradient-to-r from-clearSky-600 to-grass-600">
				<form onSubmit={handleSubmit(onSubmit)} className="max-w-[90%] space-y-6 rounded-md bg-white p-6">
					<div className="flex w-full flex-col items-center space-y-3">
						<div className="w-[100px]">
							<AnimatedLogo />
						</div>
						<h2 className="max-w-[30ch] text-center text-xl font-semibold">OCP.earth is currently in Pilot.</h2>
						<p className="max-w-[40ch] text-center text-sm">
							To access the pilot, please enter the password you have been provided below.
						</p>
					</div>

					<div className="space-y-3">
						<InputField
							{...register("password")}
							type="password"
							name="password"
							errorMessage={errors.password?.message}
							showPasswordIcon
						/>
						<Button className="w-full" type="submit">
							{isLoading ? "Logging in..." : "Access OCP.earth"}
						</Button>
					</div>

					<div className="space-y-3">
						<p className="max-w-[40ch] text-center text-sm">Interested in the Open Carbon Protocol?</p>
						<a href="mailto:admin@ocp.earth" className="w-full py-1">
							<Button className="flex w-full items-center justify-center" variant="outline" type="button">
								Contact Us <FiMessageCircle className="ml-2" />
							</Button>
						</a>
					</div>
				</form>
			</div>
		</>
	);
};

export default Home;
