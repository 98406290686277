import z from "zod";

export const emailVerificationReqParams = z.object({
	token: z.string(),
});

export const resendEmailVerificationReqParams = z.object({
	email: z.string().email(),
});

export type EmailVerificationReqParams = z.infer<typeof emailVerificationReqParams>;
export type ResendEmailVerificationReqParams = z.infer<typeof resendEmailVerificationReqParams>;
