import { ForwardedRef, forwardRef, useState } from "react";
import { Control, useForm } from "react-hook-form";
import { FiEye, FiEyeOff, FiInfo } from "react-icons/fi";

export const BasicInputField = forwardRef(
	(props: JSX.IntrinsicElements["input"], ref?: ForwardedRef<HTMLInputElement>) => (
		<input
			{...props}
			className={`flex-shrink-0 flex-grow border-none border-transparent bg-gray-50 p-2 focus:outline-none focus:ring-0 ${
				props.className ?? ""
			}`}
			ref={ref}
		/>
	),
);

BasicInputField.displayName = "BasicInputField";

const InputFieldWithoutRef = (
	{
		type = "text",
		className,
		errorMessage,
		label,
		placeholder,
		name,
		showPasswordIcon,
		helpText,
		...otherProps
	}: {
		type?: HTMLInputElement["type"];
		className?: string;
		errorMessage?: string;
		helpText?: string;
		label?: string;
		placeholder?: string;
		control?: Control<any>;
		showPasswordIcon?: boolean;
	} & ReturnType<ReturnType<typeof useForm>["register"]>,
	ref: ForwardedRef<HTMLInputElement>,
) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const onPasswordVisibilityChange = () => setIsPasswordVisible((value) => !value);
	return (
		<div className={`w-full space-y-1 ${className}`}>
			{label && (
				<label htmlFor={name} className="font-poppins text-sm font-bold">
					{label}
				</label>
			)}
			{errorMessage && <p className="text-xs font-medium text-red-500">{errorMessage}</p>}
			{helpText && <p className="my-2 text-xs text-gray-500">{helpText}</p>}

			<div
				role="group"
				className={`flex w-full items-center rounded-md border bg-gray-50 px-2 focus:border-transparent focus:outline-none focus:ring-2 ${className} ${
					errorMessage ? "border-red-500 focus:ring-red-500" : "border-gray-400 focus:ring-primary"
				}`}
			>
				{errorMessage && <FiInfo className="text-red-500" />}
				<BasicInputField
					id={name}
					name={name}
					type={isPasswordVisible ? "text" : type}
					placeholder={placeholder}
					{...otherProps}
					className="mt-1 flex-shrink-0 flex-grow border-none border-transparent bg-gray-50 p-2 focus:outline-none focus:ring-0"
					ref={ref}
				/>
				{showPasswordIcon && (
					<button type="button">
						{isPasswordVisible ? (
							<FiEyeOff className="text-gray-400" onClick={onPasswordVisibilityChange} />
						) : (
							<FiEye className="text-gray-400" onClick={onPasswordVisibilityChange} />
						)}
					</button>
				)}
			</div>
		</div>
	);
};

const InputField = forwardRef(InputFieldWithoutRef);

InputField.displayName = "InputField";
export default InputField;
