/* eslint-disable camelcase */
import z from "zod";

export const signUpReqParams = z
	.object({
		email: z.string().email(),
		password: z.string().min(1, { message: "Password must be at least 1 characters" }),
		confirm_password: z.string().min(1, { message: "Password must be at least 1 characters" }),
	})
	.refine((data) => data.password === data.confirm_password, {
		message: "Passwords don't match",
		path: ["confirm_password"],
	});

export type SignUpReqParams = z.infer<typeof signUpReqParams>;
