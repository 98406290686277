import { atomWithStorage } from "jotai/utils";

export type User = {
	id: number;
	email: string;
	accessToken: string;
	refreshToken: string;
	createdMillis: number;
	status: "PENDING" | "APPROVED" | "REJECTED" | "IN_REVIEW";
	roles: ("ROLE_PROJECT_DEVELOPER" | "ROLE_PEER_REVIEWER" | "ROLE_ADMIN")[];
};

const userAtom = atomWithStorage<User | null>("userInfo", null);

export default userAtom;
