import { accessTokenAtom, masterAccessTokenAtom } from "@/atoms/authAtoms";
import { authStore } from "@/stores/auth.store";
import { authPaths, withoutMasterLoginPaths } from "@/utils/publicRoutes";
import { ZodLocalStorage } from "@/utils/ZodLocalStorage";
import { z } from "zod";

const onRequest = async (config: any) => {
	const url = config.url;

	if (withoutMasterLoginPaths.some((path) => url?.startsWith(path))) {
		return config;
	}

	const storage = new ZodLocalStorage({
		accessToken: z.string().min(1),
		masterAccessToken: z.string().min(1),
	});

	const isMasterAccessTokenValid = storage.getItem("masterAccessToken");
	let masterAccessToken = isMasterAccessTokenValid.success ? isMasterAccessTokenValid.data : undefined;

	authStore.sub(masterAccessTokenAtom, () => {
		masterAccessToken = authStore.get(masterAccessTokenAtom);
	});

	if (masterAccessToken) {
		config.headers["master-access-token"] = masterAccessToken;
	} else {
		console.error("Master Access Token not found -> ", { url });
	}

	if (authPaths.some((path) => url?.startsWith(path))) {
		return config;
	}

	const isAccessTokenValid = storage.getItem("accessToken");
	let accessToken = isAccessTokenValid.success ? isAccessTokenValid.data : undefined;

	authStore.sub(accessTokenAtom, () => {
		accessToken = authStore.get(accessTokenAtom);
	});

	if (accessToken) {
		config.headers["x-access-token"] = accessToken;
	} else {
		console.error("Access Token not found -> ", { url });
	}
	return config;
};

const onRequestError = (error: any) => {
	return Promise.reject(error);
};

const request = { onRequest, onRequestError };

export default request;
