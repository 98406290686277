export const authPaths = [
	"/auth/signin",
	"/auth/signup",
	"/auth/reset-password",
	"/auth/master/validate",
	"/auth/token/refresh",
];

export const withoutMasterLoginPaths = [
	"/auth/master/access",
	"/auth/signup/verify-email",
	"/auth/signup/verify-email/resend",
];
