import { accessTokenAtom, masterAccessTokenAtom, refreshTokenAtom } from "@/atoms/authAtoms";
import userAtom from "@/atoms/userAtom";
import axios from "@/services/axios.config";
import { authStore } from "@/stores/auth.store";
import { ZodLocalStorage } from "@/utils/ZodLocalStorage";
import mem from "mem";
import { toast } from "react-toastify";
import { z } from "zod";

export const handleLogOut = (error: any) => {
	console.error("Error ->", error);
	authStore.set(accessTokenAtom, undefined);
	authStore.set(refreshTokenAtom, undefined);
	authStore.set(masterAccessTokenAtom, undefined);
	authStore.set(userAtom, null);
	toast.error("Session Expired.");
};

async function refreshToken() {
	try {
		const storage = new ZodLocalStorage({
			refreshToken: z.string().min(1),
		});

		// Make sure the master access token is valid, axios.response is handling logout on master-access-token expiry
		await axios.post(`/auth/master/validate`);

		const isRefreshTokenValid = storage.getItem("refreshToken");
		const refreshToken = isRefreshTokenValid.success ? isRefreshTokenValid.data : undefined;

		if (!refreshToken) {
			throw new Error("No refresh token found");
		}

		const response = await axios.post(`/auth/token/refresh`, {
			refreshToken,
		});

		authStore.set(accessTokenAtom, response.data.data["accessToken"]);
		authStore.set(refreshTokenAtom, response.data.data["refreshToken"]);
		authStore.set(masterAccessTokenAtom, response.data.data["masterAccessToken"]);

		return response.data;
	} catch (error) {
		authStore.set(accessTokenAtom, undefined);
		authStore.set(refreshTokenAtom, undefined);
		authStore.set(userAtom, null);
	}
}

// Memoize the refreshToken function with a cache expiration of 10 seconds (10000 milliseconds)
const memoizedRefreshToken = mem(refreshToken, { maxAge: 10000 });

const setTokenInHeader = (originalConfig: any) => {
	const isJsonRequest = originalConfig.headers["Content-Type"] === "application/json";

	const newAccessToken = authStore.get(accessTokenAtom);
	originalConfig.headers["x-access-token"] = newAccessToken;

	const newMasterAccessToken = authStore.get(masterAccessTokenAtom);
	originalConfig.headers["master-access-token"] = newMasterAccessToken;

	if (isJsonRequest) {
		originalConfig.data = JSON.stringify(originalConfig.data);
	} else if (originalConfig.headers["Content-Type"] === "multipart/form-data") {
		// If it was a multipart form data request, create a new FormData instance and add the request data to it
		const formData = new FormData();
		for (const key in originalConfig.data) {
			formData.append(key, originalConfig.data[key]);
		}
		originalConfig.data = formData;
	}
};

const helpers = {
	setTokenInHeader,
	memoizedRefreshToken,
};

export default helpers;
